body {
  font-size: var(--fontsize-body);
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 1.22;
  color: var(--color-text);
}

p {
  margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.06;
  hyphens: auto;
}

h1 {
  margin-bottom: 0.25em;
  font-size: var(--fontsize-h2);

  &.intro {
    font-size: var(--fontsize-body--large);
    font-weight: 400;
  }

  @include tablet-landscape-up {
    font-size: var(--fontsize-h1);
  }
}

h2 {
  margin-bottom: 0.5em;
  font-size: var(--fontsize-h2);
}

h3 {
  margin-bottom: 0.5em;
  font-size: var(--fontsize-body--large);
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}
