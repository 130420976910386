.color-white {
  background-color: var(--color-white);
  color: var(--color-text);
}

.color-blue,
.color-darkblue,
.color-orange,
.color-rose,
.color-mint,
.color-brown,
.color-darkgold,
.color-green,
.color-peach {
  color: var(--color-white);
}

.color-blue {
  background: var(--color-blue) !important;
}

.color-darkblue {
  background: var(--color-darkblue) !important;
}

.color-orange {
  background: var(--color-orange) !important;
}

.color-rose {
  background: var(--color-rose) !important;
}

.color-mint {
  background: var(--color-mint) !important;
}

.color-brown {
  background: var(--color-brown) !important;
}

.color-darkgold {
  background: var(--color-darkgold) !important;
}

.color-green {
  background: var(--color-green) !important;
}

.color-peach {
  background: var(--color-peach) !important;
}

.color-darkorange {
  background: var(--color-darkorange) !important;
}
