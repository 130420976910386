.front {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-landscape-up {
    flex-direction: row;
  }
}

.front__col {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include tablet-landscape-up {
    width: 50%;
  }
}

.front__block {
  position: relative;
}

.front__col:first-of-type .front__block:nth-child(2),
.front__col:last-of-type .front__block:nth-child(2) {
  flex: 1;
}

.front__block:last-child {
  width: 100%;

  @include tablet-portrait-up {
    width: 80%;
  }
}

.front__col:first-of-type .front__block:last-child {
  margin-left: auto;
}

.front__block__content {
  display: flex;
  width: 100%;
  height: 100%;
}

.front__block__text {
  padding: 3.25rem 2.625rem;
  overflow: hidden;

  @include tablet-portrait-up {
    max-width: 75%;
  }
}

.front__block__intro {
  font-size: var(--fontsize-h1);
}

.front__block__title {
  margin-bottom: 0.5em;
  font-size: var(--fontsize-h2);
}

.front__block__image {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.front__block--image .front__block__image {
  width: 40%;
  height: 100%;
  flex: 1 0 auto;
}

.front__block__link {
  position: absolute;

  svg {
    width: 63px;
    height: 45px;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: translateX(10%);
  }
}

.front__block__link--white svg {
  path {
    fill: var(--color-white);
  }
}

.front__col:last-of-type .front__block:nth-child(2) {
  .front__block__content {
    flex-direction: column;
  }

  .front__block__image {
    width: 100%;
    height: 75vw;
  }

  .front__block__graphic {
    position: absolute;
    left: 0;
    top: 0;
    width: 35%;
    transform: translate(1.5rem, -50%);
    z-index: 100;
  }

  @include tablet-portrait-up {
    .front__block__image {
      width: 40%;
      height: 100%;
    }

    .front__block__content {
      flex-direction: row;
    }

    .front__block__graphic {
      width: 20%;
      max-width: 200px;
      left: inherit;
      top: inherit;
      right: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }
}

// Arrow placements
.front__block__text {
  padding-bottom: 8rem;
}

.front__block__link {
  right: 3.25rem;
  bottom: 3.25rem;
}

@include tablet-portrait-up {
  .front__block__text {
    padding-bottom: 2.625rem;
  }

  .front__col:first-of-type .front__block:nth-child(2) .front__block__link,
  .front__col:nth-of-type(2) .front__block:first-child .front__block__link {
    top: 3.25rem;
    right: 3.25rem;
  }

  .front__col:nth-of-type(2) .front__block:nth-child(2) .front__block__link,
  .front__col:nth-of-type(2) .front__block:nth-child(3) .front__block__link {
    right: 3.25rem;
  }

  .front__col:nth-of-type(2) .front__block:nth-child(3) .front__block__link {
    bottom: 3.25rem;
  }
}

