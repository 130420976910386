.privacy {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.privacy__col {
  width: 100%;
  flex-direction: column;

  @include tablet-landscape-up {
    &:first-child {
      width: 60%;
    }

    &:last-child {
      width: 40%;
    }
  }
}

.privacy__block {
  position: relative;
}

.privacy__block__content {
  padding: 3.25rem 2.625rem;
  overflow: hidden;
  color: var(--color-white);

  h3 {
    margin-bottom: 0.75rem;
    font-size: var(--fontsize-h2);
  }

  a {
    color: var(--color-white);
  }

  @include tablet-portrait-up {
    max-width: 580px;
  }
}

.privacy__col:first-of-type .privacy__block {
  background: var(--color-brown);
}

.privacy__col:last-of-type .privacy__block:last-child {
  background: var(--color-mint);

  .privacy__block__content {
    padding-bottom: 8rem;
  }
}

.privacy__block__person:not(:last-child) {
  margin-bottom: 3rem;
}

.privacy__block__image {
  width: 80%;

  img {
    width: 100%;
    height: auto;
  }
}

.privacy__block__arrow {
  position: absolute;
  right: 3.25rem;
  bottom: 3.25rem;

  svg {
    width: 63px;
    height: 45px;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: translateX(10%);
  }
}

.privacy__block__graphic {
  position: absolute;
  max-width: 50%;
  top: 0;
  right: 0;
  transform: translate(-25%, -75%);
  z-index: 50;

  @include phablet-up {
    max-width: 30%;
  }

  @include tablet-landscape-up {
    bottom: 0;
    left: 0;
    transform: translate(-75%, 60%);
  }
}
