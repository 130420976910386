.banner {
  width: 100%;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-black);

  @include tablet-landscape-up {
    height: 56px;
  }
}

.banner__content {
  width: max-content;
  display: flex;
  line-height: 36px;
  transform: translateX(0.3%);

  @include tablet-landscape-up {
    line-height: 56px;
  }
}

.banner__list {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;

  @include tablet-landscape-up {
    font-size: 42px;
  }
}

.banner__item {
  margin-right: 1em;
}
