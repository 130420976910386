@mixin phablet-up {
  @media (min-width: 412px) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: 609px) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (min-width: 919px) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-large-up {
  @media (min-width: 1920px) {
    @content;
  }
}
