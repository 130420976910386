.footer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 2.625rem;

  @include tablet-landscape-up {
    flex-direction: row;
    padding: 4.5rem 3.825rem 0;
  }
}

.footer__col {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.625rem;
  padding: 0 2.625rem;

  @include tablet-landscape-up {
    width: columns(6, 24);
    margin-bottom: 0;
    padding: 0 0 2.625rem 0;

    + .footer__col {
      margin-left: columns(1, 24);
    }
  }
}

.footer__heading {
  margin-bottom: 1em;
}

.footer__item {
  margin-bottom: 0.125em;

  a {
    text-decoration: none;
    color: var(--color-text);
  }
}

.footer__cookies {
  margin-top: 2.625rem;
  text-decoration: none;
  color: var(--color-text);
  font-weight: 700;
}

.footer__graphic {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2.625rem;
  padding: 0 25%;

  @include tablet-landscape-up {
    width: columns(6, 24);
    margin-left: auto;
    margin-bottom: 2.5rem;
    transform: translateY(-7.5%);
    padding: 0 0 0 2.5rem;
  }
}
