.dilemma,
.dilemma__group {
  display: flex;
  flex-wrap: wrap;
}

.dilemma {
  width: 100%;
}

.dilemma__group {
  width: 100%;

  @include tablet-portrait-up {
    &:first-child,
    &:nth-child(2) {
      width: 50%;
    }

    &:nth-child(3),
    &:last-child {
      width: 100%;
    }
  }
}

.dilemma__block {
  position: relative;
  width: 100%;
}

.dilemma__block__content {
  padding: 3.25rem 2.625rem 7rem;
  overflow: hidden;
  color: var(--color-white);

  @include tablet-portrait-up {
    max-width: 580px;
  }
}

.dilemma__block__arrow {
  position: absolute;
  right: 3.25rem;
  bottom: 2.625rem;
  cursor: pointer;

  svg {
    width: 63px;
    height: 45px;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: translateX(10%);
  }
}

.dilemma__block__image {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dilemma__block__graphic {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.dilemma__group:first-child .dilemma__block {
  width: 100%;

  &:first-child {
    background: var(--color-mint);
  }

  &:nth-child(2) {
    background: var(--color-darkorange);
  }

  &:nth-child(3) {
    order: 4;
    background: var(--color-brown);
  }

  &:nth-child(4) {
    order: 5;
    background: var(--color-blue);
  }

  &:nth-child(5) {
    order: 3;
  }

  @include tablet-portrait-up {
    &:nth-child(2) {
      width: 60%;
    }

    &:nth-child(3) {
      width: 50%;
    }

    &:nth-child(4) {
      width: 50%;
    }
  }
}

.dilemma__group:nth-child(2) .dilemma__block {
  &:first-child {
    order: 2;
    background: var(--color-rose);
  }

  &:nth-child(2) {
    order: 3;
    background: var(--color-darkgold);
  }

  &:nth-child(3) {
    order: 4;
    background: var(--color-darkblue);

    svg path {
      fill: var(--color-white);
    }
  }

  &:nth-child(4) {
    order: 1;
  }

  &:nth-child(5) {
    order: 5;
    background: var(--color-orange);
  }

  @include tablet-portrait-up {
    &:first-child {
      width: 50%;
      height: calc(100% / 3);
    }

    &:nth-child(2) {
      width: 50%;
      height: calc(100% / 3 - 5rem);
    }

    &:nth-child(3) {
      width: 40%;
      height: calc(100% / 3);
      margin-top: -5rem;
    }

    &:nth-child(4) {
      width: 50%;
      height: calc(100% / 3 + 5rem);
    }

    &:nth-child(5) {
      width: 80%;
      height: calc(100% / 3);
      margin-left: auto;
    }
  }
}

.dilemma__group:nth-child(3) .dilemma__group__col {
  width: 100%;

  &:first-child .dilemma__block {
    background: var(--color-rose);
  }

  &:nth-child(2) .dilemma__block {
    background: var(--color-rose);
  }

  &:last-child .dilemma__block:first-child {
    background: var(--color-darkorange);
  }

  &:last-child .dilemma__block:last-child {
    background: var(--color-blue);
  }

  @include tablet-portrait-up {
    width: calc(99.999% / 3);

    &:first-child .dilemma__block {
      height: 80%;
    }

    &:nth-child(2) .dilemma__block {
      height: 100%;
    }

    &:last-child .dilemma__block:first-child {
      height: 40%;
    }

    &:last-child .dilemma__block:last-child {
      width: 60%;
      height: 60%;
      margin-left: auto;
    }
  }
}

.dilemma__group:nth-child(3) .dilemma__block {
  width: 100%;
}

.dilemma__group:last-child {
  flex-direction: column-reverse;

  @include tablet-portrait-up {
    flex-direction: row;
  }
}

.dilemma__group:nth-child(4) .dilemma__group__row {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child .dilemma__block {
    background: var(--color-darkblue);
  }

  &:last-child .dilemma__block:first-child {
    background: var(--color-peach);
  }

  &:last-child .dilemma__block:last-child {
    background: var(--color-green);
  }

  @include tablet-portrait-up {
    width: 50%;

    &:first-child .dilemma__block {
      flex: 1;
    }

    &:last-child .dilemma__block:first-child {
      height: 45%;
    }

    &:last-child .dilemma__block:last-child {
      width: 70%;
      height: 55%;
    }

    .dilemma__block__graphic img {
      max-height: 25rem;
    }
  }
}

.dilemma-modals {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;

  &.is-active {
    pointer-events: auto;
  }
}

.dilemma-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 2.5rem;
  left: 0;
  padding: 2rem;
  background: var(--color-mint);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  overflow-y: scroll;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07)
  ;

  &.is-visible {
    opacity: 1;
    pointer-events: auto;
  }

  @include tablet-portrait-up {
    width: 75%;
    height: max-content;
    max-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    top: 50%;
    left: 50%;
    padding: 2.5rem;
    justify-content: space-between;
    transform: translate(-50%, -50%);
  }

  &:nth-child(2) {
    background: var(--color-darkorange);
  }

  &:nth-child(3) {
    background: var(--color-brown);
  }

  &:nth-child(4) {
    background: var(--color-blue);
  }

  &:nth-child(5) {
    background: var(--color-rose);
  }

  &:nth-child(6) {
    background: var(--color-darkgold);
  }

  &:nth-child(7) {
    background: var(--color-darkblue);
    color: var(--color-white);

    svg path {
      fill: var(--color-white);
    }
  }

  &:nth-child(8) {
    background: var(--color-rose);
  }

  &:nth-child(9) {
    background: var(--color-darkorange);
  }

  &:nth-child(10) {
    background: var(--color-blue);
  }

  &:nth-child(11) {
    background: var(--color-peach);
  }

  &:nth-child(12) {
    background: var(--color-green);
  }
}

.dilemma-modal__heading {
  width: 100%;
  margin-top: 4rem;
  font-size: var(--fontsize-h2);

  @include tablet-portrait-up {
    font-size: var(--fontsize-h1);
    margin-top: 0;
    margin-bottom: 1em;
  }
}

.dilemma-modal__close {
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  cursor: pointer;

  @include tablet-portrait-up {
    right: 2.5rem;
  }
}

.dilemma-modal__texts,
.dilemma-modal__image {
  width: 100%;

  @include tablet-portrait-up {
    width: calc(50% - 0.5rem);
  }
}

.dilemma-modal__subheading,
.dilemma-modal__questions {
  color: var(--color-white);
}

.dilemma-modal__subheading {
  margin: 2em 0 1em;
}

.dilemma-modal__questions {
  margin-bottom: 2em;

  @include tablet-portrait-up {
    margin-bottom: 0;
  }
}

.dilemma-modal__caption {
  margin-top: 1em;
}
