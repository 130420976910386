.change {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-landscape-up {
    flex-direction: row;
    justify-content: stretch;
  }
}

.change__col {
  display: flex;
  flex-direction: column;
}

.change__col:first-of-type {
  @include tablet-landscape-up {
    width: 60%;
  }
}

.change__col:last-of-type {
  flex-direction: column-reverse;

  @include tablet-landscape-up {
    flex-direction: column;
    flex: 1;
  }
}

.change__block {
  position: relative;
}

.change__block__content {
  padding: 3.25rem 2.625rem;
  overflow: hidden;
  color: var(--color-white);

  @include tablet-portrait-up {
    max-width: 580px;
  }
}

.change__col:first-of-type .change__block:first-child,
.change__col:last-of-type .change__block:nth-child(2) {
  padding-bottom: 6rem;
  flex: 1;
}

.change__col:first-of-type .change__block:first-child {
  background: var(--color-brown);
}

.change__col:first-of-type .change__block:last-child {
  @include tablet-landscape-up {
    width: 50%;
    margin-left: auto;
  }
}

.change__col:last-of-type .change__block:nth-child(2) {
  background: var(--color-mint);
}

.change__col:last-of-type .change__block:first-child {
  width: 80%;
}

.change__block__link {
  position: absolute;
  right: 3.25rem;
  bottom: 3.25rem;

  svg {
    width: 63px;
    height: 45px;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: translateX(10%);
  }
}

.change__block__graphic {
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  width: 35%;
  transform: translateY(50%);
  z-index: 50;

  @include tablet-portrait-up {
    left: 2.625rem;
    width: 20%;
    max-width: 200px;
  }
}
