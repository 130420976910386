:root {
  --color-text: #000000;
  --color-white: #ffffff;
  --color-gray: #cecece;
  --color-blue: #5ABAEA;
  --color-darkblue: #00395B;
  --color-orange: #F4B23E;
  --color-darkorange: #EC612B;
  --color-rose: #EAA9A0;
  --color-mint: #AFB88C;
  --color-brown: #CA7A2E;
  --color-darkgold: #AE8E34;
  --color-green: #8DC63F;
  --color-peach: #F0AF6E;

  --fontsize-h1: 2.875rem;
  --fontsize-h2: 1.5rem;
  --fontsize-h3: 2.375rem;
  --fontsize-body: 1.125rem;
  --fontsize-body--large: 1.125rem;
  --fontsize-body--small: 1rem;

  @include tablet-landscape-up {
    --fontsize-h1: 4rem;
    --fontsize-body--large: 1.5rem;
  }
}
