.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 500;
  transform: translate(0, calc(-100% + 3.625rem));
  transition: transform 0.4s cubic-bezier(0.45, 0, 0.55, 1);

  a {
    color: var(--color-black);
    text-decoration: none;
  }

  &.is-open {
    transform: translate(0, 0);
  }

  @include tablet-landscape-up {
    transform: translate(calc(-100% + 3.625rem), 0);
  }
}

.navigation__toggle {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 3.625rem;
  display: flex;
  justify-content: center;
  background: var(--color-orange);
  pointer-events: all;
  z-index: 600;
}

.navigation__toggle__open,
.navigation__toggle__close {
  position: absolute;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  cursor: pointer;
  transition: opacity 0.1s ease;

  @include tablet-landscape-up {
    transition: none;
  }
}

.navigation__toggle__open {
  bottom: 1.25rem;

  @include tablet-landscape-up {
    bottom: auto;
    top: 1.25rem;
  }
}

.navigation__toggle__close {
  top: 1.25rem;
  pointer-events: none;
  opacity: 0;
}

.is-open .navigation__toggle__close {
  opacity: 1;
  pointer-events: all;
}

.is-open .navigation__toggle__open {
  pointer-events: none;
  opacity: 0;
}

.navigation__content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 3.875rem 2rem 4.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background: var(--color-orange);
  pointer-events: all;
  z-index: 500;

  @include tablet-landscape-up {
    padding: 8rem 6.25rem 6.25rem 8.5rem;
  }
}

.navigation__primary,
.navigation__secondary,
.navigation__sponsor {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s cubic-bezier(0.45, 0, 0.55, 1);
}

.is-open .navigation__primary,
.is-open .navigation__secondary,
.is-open .navigation__sponsor {
  opacity: 1;
  pointer-events: auto;
}

.navigation__sponsor,
.navigation__primary {
  margin-bottom: 2rem;

  @include desktop-up {
    margin-bottom: 0;
  }
}

.navigation__primary {
  font-size: var(--fontsize-h2);
  font-weight: 700;
  order: 2;

  @include desktop-up {
    order: 1;
    font-size: 4vw;
  }
}

.navigation__primary__item:not(:last-child) {
  margin-bottom: 1.5rem;
}

.navigation__sponsor {
  order: 1;
  width: 100%;

  img {
    max-width: 170px;
    height: auto;
  }

  @include desktop-up {
    max-width: 230px;
    margin-left: auto;
    order: 2;

    img {
      max-width: 230px;
    }
  }
}


.navigation__secondary {
  margin-top: auto;
  width: 100%;
  order: 3;
  font-size: var(--fontsize-body);

  @include desktop-up {
    font-size: var(--fontsize-h2);
  }
}

.navigation__secondary__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navigation__secondary__item {
  width: calc(99.999% / 2);
  margin-top: 1.5rem;

  @include tablet-landscape-up {
    width: calc(99.999% / 3);
  }
}
