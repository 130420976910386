.about {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.about__col {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-landscape-up {
    flex-direction: row;
  }
}

.about__block {
  width: 100%;
}

.about__block__content {
  padding: 3.25rem 2.625rem;
  overflow: hidden;

  @include tablet-portrait-up {
    max-width: 580px;
  }
}

.about__block__image {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about__block__heading {
  margin-bottom: 0.75rem;
}

.about__col:first-child .about__block:last-child {
  background: var(--color-darkblue);
  color: var(--color-white);
}

.about__col:last-child .about__block:first-child {
  color: var(--color-text);
}

@include tablet-landscape-up {
  .about__col:first-of-type .about__block:first-child {
    width: 40%;
  }

  .about__col:first-of-type .about__block:last-child {
    width: 60%;
  }

  .about__col:nth-of-type(2) .about__block:first-child {
    width: 50%;
  }

  .about__col:nth-of-type(2) .about__block:last-child {
    width: 50%;
    margin-right: 10%;
    background: var(--color-orange);

    .about__block__image {
      width: 50%;
      margin-left: auto;
    }
  }
}

.about__contributors {
  width: 100%;
  padding: 4rem 2.625rem 0;
  display: flex;
  flex-wrap: wrap;
  background: var(--color-blue);
  color: var(--color-white);
  overflow: hidden;

  @include tablet-landscape-up {
    padding: 6rem 2.625rem 0;
  }
}

.about__contributors__block {
  width: 100%;
  margin-bottom: 4rem;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  @include tablet-portrait-up {
    width: calc((99.999% / 2) - (3rem / 2));
    margin-bottom: 3rem;

    &:not(:nth-child(2n)) {
      margin-right: 3rem;
    }
  }

  @include tablet-landscape-up {
    width: calc((99.999% / 3) - (4rem / 3 * 2));
    margin-bottom: 4rem;

    &:not(:nth-child(2n)) {
      margin-right: inherit;
    }

    &:not(:nth-child(3n)) {
      margin-right: 4rem;
    }
  }
}

.about__contributors__heading {
  font-size: var(--fontsize-h1);
}
