.sidenav {
  width: 100%;
  padding: 3.25rem 2.375rem;
  font-size: var(--fontsize-body--small);
  color: var(--color-black);

  @include tablet-landscape-up {
    width: columns(5, 24);
  }
}

.sidenav__title,
.sidenav__heading,
.sidenav__chapters {
  margin-bottom: 1em;
}

.sidenav__heading,
.sidenav__chapter {
  cursor: pointer;
}

.sidenav__chapters[data-visible="false"] {
  display: none;
}

.sidenav__chapter {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 1em;

  &[data-active="true"]::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    width: 0.5625em;
    height: 0.5625em;
    border-radius: 50%;
    background: var(--color-text);
    transform: translateY(-50%);
  }
}
