.header,
.header__logo,
.header__content {
  display: flex;
}

.header {
  width: 100%;
  flex-direction: column;

  @include tablet-landscape-up {
    flex-direction: row;
  }
}

.header__logo {
  width: 100%;
  padding: 0 12.5% 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet-portrait-up {
    padding: 1.5rem 25%;
  }

  @include tablet-landscape-up {
    width: columns(7, 24);
    padding: 0;
  }
}

.header__content {
  flex: 1;
  padding: 4rem 2.625rem;
  color: var(--color-black);

  @include tablet-landscape-up {
    padding: 2.625rem 4.5rem;
  }
}

.header__headings {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: var(--color-white);
  }

  &[data-visible="false"] {
    display: none;
  }
}

