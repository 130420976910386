.bagom {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color-white);

  @include tablet-landscape-up {
    flex-direction: row;
    justify-content: stretch;
  }

  a {
    text-decoration: underline;
    color: currentColor;
  }

  strong {
    font-weight: 700;
  }
}

.bagom__col {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-landscape-up {
    width: 50%;
  }
}

.bagom__block__content {
  padding: 3.25rem 2.625rem;
  overflow: hidden;
  color: var(--color-white);

  @include tablet-portrait-up {
    max-width: 580px;
  }
}

.bagom__col:first-of-type .bagom__block:first-child {
  background: var(--color-mint);
}

.bagom__col:first-of-type .bagom__block:nth-child(2) {
  position: relative;
  width: 80%;
  margin-left: auto;
  background: var(--color-darkorange);

  .bagom__block__graphic {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-25%, 75%);
    max-width: 50%;
    z-index: 50;
  }

  @include phablet-up {
    .bagom__block__graphic {
      max-width: 30%;
    }
  }

  @include tablet-landscape-up {
    .bagom__block__graphic {
      left: unset;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
    }
  }

  @include desktop-large-up {
    .bagom__block__graphic {
      transform: translate(-10%, 50%);
    }
  }
}

.bagom__col:last-of-type .bagom__block:first-child {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  background: var(--color-rose);

  .bagom__block__image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include tablet-landscape-up {
    flex-direction: row;

  .bagom__block__image {
    width: 40%;
    height: 100%;
    flex: 1 0 auto;
  }
  }
}

.bagom__col:last-of-type .bagom__block:nth-child(2) {
  width: 80%;
  background: var(--color-darkblue);
}
