.chapters {
  display: flex;
  width: 100%;

  @include tablet-landscape-up {
    width: columns(19, 24);
  }
}

.chapter {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &[data-visible="false"] {
    display: none;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 1.25em;
    padding-left: 1em;
  }

  li {
    margin-bottom: 0.25em;
  }

  @include tablet-landscape-up {
    flex-direction: row;
  }
}

.chapter__col {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-landscape-up {
    width: columns(8, 19);
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.chapter__col--large {
  width: 100%;

  @include tablet-landscape-up {
    width: columns(11, 19);
  }
}

.chapter__text,
.chapter__media,
.chapter__navigation {
  width: 100%;
}

.chapter__text {
  padding: 3.25rem 2.375rem;
  color: var(--color-white);
}

.chapter__media {
  position: relative;
}

.chapter__placeholder {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background: var(--color-peach);
}

.chapter__media__graphic {
  display: none;

  @include tablet-landscape-up {
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 30%;
    max-width: 200px;
    transform: translate(-50%, -50%);
  }
}

.chapter__video,
.chapter__optout {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  > * {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
  }
}

.chapter__optout {
  background: var(--color-gray);
}

.chapter__optout__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
}

.chapter__optout__button {
  display: inline-block;
  margin-top: 1em;
  padding: 0.5em 1em;
  border: 1px solid var(--color-text);
  border-radius: 99999px;
  text-decoration: none;
  line-height: 1;
  color: var(--color-text);
  transition: all 0.1s;

  &:hover {
    background: var(--color-text);
    color: var(--color-gray);
  }
}

.chapter__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 10rem;
}

.chapter__navigation__arrow {
  width: 6.6875rem;
  height: 4.625rem;
  cursor: pointer;

  svg {
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: translateX(10%);
  }

  @include tablet-landscape-up {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-40%, -50%);
  }
}
