.wrapper,
.content,
.main {
  width: 100%;
}

.wrapper {
  position: relative;
  margin-top: 3.625rem;

  @include tablet-landscape-up {
    margin-top: 0;
    padding-top: 0;
    padding-left: 3.625rem;
  }
}

.main {
  display: flex;
  flex-wrap: wrap;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-orange);
  z-index: 1000;
  transform: translateX(-100%);
  pointer-events: none;
}
